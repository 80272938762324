<template>
  <div class="lyLayer">
    <div class="mdSelect" @click.self="cancel">
      <div class="mdSelect-wrapper">
        <div class="mdSelect-header">
          <section class="stTitle">
            <div class="title">지역</div>
            <i class="close" @click="cancel"> 닫기 </i>
          </section>
        </div>
        <div class="mdSelect-body">
          <!-- 활성 : is-active -->
          <section class="stSearch is-active">
            <div class="search">
              <!-- 지우기 활성 : is-activeClear -->
              <div class="cmSearch">
                <div class="cmSearch-input">
                  <byte-input
                    placeholder="지역정보를 입력하세요."
                    class="cmSearch-inputInput"
                    :max-byte="100"
                    v-model="locationValue"
                    @keydown.native.enter="search"
                    @click.native="logEvent('community_location_search_click')"
                  />
                  <i class="cmSearch-inputSearch" @click="search"> 검색 </i>
                  <i
                    class="cmSearch-inputClear"
                    @click="
                      logEvent('community_location_search_remove', () => {
                        locationValue = '';
                      })
                    "
                  >
                    지우기
                  </i>
                </div>
                <p class="cmSearch-validation" v-if="isSearchInvalid">
                  주소는 두 글자 이상 입력해주세요.
                </p>
              </div>
            </div>
            <!-- 활성 : is-active -->
            <div
              class="matching"
              :class="{ 'is-active': locationList }"
              ref="scrollContainer"
            >
              <div class="matching-object">
                <div class="cmMatching">
                  <ul class="cmMatching-list" ref="scrollList">
                    <li
                      class="cmMatching-item"
                      v-for="location in locationList"
                      :key="location.addrId"
                      v-html="location.locationHtml"
                      @click="selectLocation(location)"
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ByteInput from "@/components/ByteInput";
import { boardApi } from "@/api";
import scrollMixin from "@/mixins/scrollMixin";
import { keyboardDown } from "@/util/webview";

export default {
  name: "LocationSearchModal",
  mixins: [scrollMixin],
  components: {
    ByteInput,
  },
  beforeCreate() {
    document.querySelector("body").classList.add("is-activeModalSelect");
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("is-activeModalSelect");
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
      locationValue: "",
      searchParams: {},
      locationList: null,
      isSearchInvalid: false,
    };
  },
  methods: {
    cancel() {
      this.locationSearchState.resolve();
    },
    async setData() {
      if (this.isFetching) {
        return;
      }
      if (!this.searchParams.keyword) {
        return;
      }
      try {
        this.isFetching = true;
        this.isSearchInvalid = false;
        const {
          data: { content, total },
        } = await boardApi.getAddrList({
          ...this.searchParams,
          page: this.pageIndex,
        });
        this.locationList = this.locationList || [];
        this.locationList = [
          ...this.locationList,
          ...content.map((location) => ({
            ...location,
            locationHtml: this.getHighlightHtml(location),
          })),
        ];
        this.totalCount = total;
      } finally {
        this.isFetching = false;
      }
    },
    async search() {
      this.removeScrollEvent();
      keyboardDown();
      if (this.locationValue?.length < 2) {
        this.isSearchInvalid = true;
        return;
      }
      this.$refs.scrollContainer?.scrollTo(0, 0);
      this.pageIndex = 1;
      this.locationList = null;
      this.searchParams = {
        keyword: this.locationValue,
        size: this.pageSize,
      };
      await this.setData();
      this.addScrollEvent();
    },
    getHighlightHtml(location) {
      return location.address.replace(
        new RegExp(this.locationValue, "g"),
        `<strong class="cmMatching-strong">${this.locationValue}</strong>`
      );
    },
    selectLocation(location) {
      this.locationSearchState.resolve(location);
    },
  },
  computed: {
    ...mapGetters(["locationSearchState"]),
  },
};
</script>
