<template>
  <div class="lyLayer">
    <transition duration="300" name="layer">
      <location-search-modal v-if="activeLocationSearch" />
      <select-modal v-if="activeSelect" />
      <etc-form-modal v-if="activeEtcForm" />
      <floating-button-modal v-if="activeFloatingButton" />
    </transition>
  </div>
</template>

<script>
import LocationSearchModal from "@/components/modal/community/LocationSearchModal";
import SelectModal from "@/components/modal/community/SelectModal";
import EtcFormModal from "@/components/modal/community/EtcFormModal";
import FloatingButtonModal from "@/components/modal/community/FloatingButtonModal";
import { mapGetters, mapActions } from "vuex";
import { isBottomSheetShow } from "@/util/webview";

export default {
  name: "CommunityModal",
  components: {
    LocationSearchModal,
    SelectModal,
    EtcFormModal,
    FloatingButtonModal,
  },
  methods: {
    ...mapActions([
      "deactivateSelect",
      "deactivateLocationSearch",
      "deactivateEtcForm",
      "deactivateFloatingButton",
    ]),
  },
  computed: {
    ...mapGetters(["activeLocationSearch", "activeSelect", "activeEtcForm", "activeFloatingButton"]),
    isBottomSheetShow() {
      return (
        this.activeLocationSearch || this.activeSelect || this.activeEtcForm
      );
    },
  },
  watch: {
    $route() {
      this.deactivateSelect();
      this.deactivateLocationSearch();
      this.deactivateEtcForm();
      this.deactivateFloatingButton();
    },
    isBottomSheetShow(val) {
      isBottomSheetShow({ isShow: val });
    },
  },
};
</script>

<style scoped>
>>> .mdSelect-wrapper {
  transform: translateY(0);
}
.layer-enter >>> .mdSelect-wrapper,
.layer-leave-to >>> .mdSelect-wrapper {
  transform: translateY(100%);
}
</style>
