<template>
  <div class="lyLayer">
    <div class="mdSelect" @click.self="cancel">
      <div class="mdSelect-wrapper">
        <div class="mdSelect-header">
          <section class="stTitle">
            <div class="title">더보기</div>
            <i class="close" @click="cancel"> 닫기 </i>
          </section>
        </div>
        <div class="mdSelect-body">
          <!-- 활성 : is-active -->
          <section class="stEtc is-active">
            <div class="input">
              <div class="cmTextarea">
                <div class="cmTextarea-input">
                  <byte-textarea
                    class="cmTextarea-inputTextarea"
                    placeholder="기타 사유를 입력해주세요."
                    :max-byte="500"
                    v-model="content"
                    @click.native="
                      logEvent('community_more_report_etc_input_click')
                    "
                  ></byte-textarea>
                </div>
                <!-- '0'이 아닐때 상태 : is-stateNonzero -->
                <div
                  class="cmTextarea-limit"
                  :class="{ 'is-stateNonzero': !content.length }"
                >
                  <strong class="cmTextarea-limitStrong">{{
                    contentByte | comma
                  }}</strong
                  >/500
                </div>
              </div>
            </div>
            <div class="button">
              <div class="cmButton">
                <ul class="cmButton-list">
                  <li
                    class="cmButton-item"
                    @click="
                      logEvent('community_more_report_etc_cancel', cancel)
                    "
                  >
                    <div class="cmButton-object">취소</div>
                  </li>
                  <li
                    class="cmButton-item is-colorKey"
                    @click="
                      logEvent('community_more_report_etc_confirm', submit)
                    "
                  >
                    <div class="cmButton-object">확인</div>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ByteTextarea from "@/components/ByteTextarea";
import { comma, getByteLength } from "@/util/string";

export default {
  name: "EtcFormModal",
  beforeCreate() {
    document.querySelector("body").classList.add("is-activeModalSelect");
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("is-activeModalSelect");
  },
  components: {
    ByteTextarea,
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    cancel() {
      this.etcFormState.resolve();
    },
    submit() {
      if (!this.validateContent()) {
        return;
      }
      this.etcFormState.resolve(this.content);
    },
    validateContent() {
      if (!this.content.trim()) {
        alert("기타 사유를 입력해주세요.");
        return false;
      }
      return true;
    },
  },
  computed: {
    ...mapGetters(["etcFormState"]),
    contentByte() {
      return getByteLength(this.content);
    },
  },
  filters: {
    comma(value) {
      return comma(value);
    },
  },
};
</script>
