<template>
  <div class="lyWrapper">
    <router-view />
    <community-modal-container />
  </div>
</template>

<script>
import "@/assets/js/common";
import CommunityModalContainer from "@/components/modal/community/CommunityModalContainer";

export default {
  name: "CommunityLayout",
  components: { CommunityModalContainer },
};
</script>

<style scoped>
@import "~@/assets/css/community/normalize.css";
@import "~@/assets/css/community/common.css";
@import "~@/assets/css/community/content.css";
@import "~@/assets/css/community/custom.css";

.lyWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overscroll-behavior: auto;
  min-height: calc(var(--vh, 1vh) * 100);
}
</style>
