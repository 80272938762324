<template>
  <div class="lyLayer">
    <div class="mdSelect" @click.self="cancel">
      <div class="mdSelect-wrapper">
        <div class="mdSelect-header">
          <section class="stTitle">
            <div class="title">{{ title }}</div>
            <i class="close" @click="cancel"> 닫기 </i>
          </section>
        </div>
        <div class="mdSelect-body">
          <!-- 활성 : is-active -->
          <section class="stSelect is-active">
            <div class="select">
              <ul class="select-list">
                <!-- 선택 상태 : is-stateSelect -->
                <li
                  v-for="option in options"
                  :key="option.value"
                  @click="selectOption(option.value)"
                  :class="{
                    'is-stateSelect': option.value === selectedOption,
                  }"
                  class="select-item"
                >
                  {{ option.text }}
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectModal",
  beforeCreate() {
    document.querySelector("body").classList.add("is-activeModalSelect");
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("is-activeModalSelect");
  },
  methods: {
    selectOption(value) {
      let eventName = "";
      switch (value) {
        //  게시글 더보기
        case "edit":
          eventName = "community_more_modify_click";
          break;
        case "delete":
          eventName = "community_more_delete_click";
          break;
        case "report":
          eventName = "community_more_report_click";
          break;
        //  게시글 더보기 신고
        case 10101:
          eventName = "community_more_report_spam_click";
          break;
        case 10102:
          eventName = "community_more_report_swear_click";
          break;
        case 10103:
          eventName = "community_more_report_violent_click";
          break;
        case 10104:
          eventName = "community_more_report_hatred_click";
          break;
        case 10105:
          eventName = "community_more_report_fake_click";
          break;
        case 10106:
          eventName = "community_more_report_ilegal_click";
          break;
        case 10107:
          eventName = "community_more_report_etc_click";
          break;
        //  게시물 등록
        case 10001:
          eventName = "community_posting_category_free_click";
          break;
        case 10002:
          eventName = "community_posting_category_qna_click";
          break;
      }
      if (eventName) {
        this.logEvent(eventName);
      }
      this.selectState.resolve(value);
    },
    cancel() {
      this.selectState.resolve();
    },
  },
  computed: {
    ...mapGetters(["selectState"]),
    title() {
      return this.selectState?.title || "\u00A0";
    },
    options() {
      return this.selectState?.options || [];
    },
    selectedOption() {
      return this.selectState?.selectedOption;
    },
  },
};
</script>
